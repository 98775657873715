import { OnlineStatus, CookieChecker } from '@datapeace/1up-frontend-web-ui';
import {
  AuthProvider,
  ConfigProvider,
  ProcessDataProvider,
  Routes,
  LocationProvider,
} from '@datapeace/ams-web-components';
import { AppInitializerContainer } from '@datapeace/ams-web-containers';

function App() {
  return (
    <CookieChecker logoUrl="/images/ams_logo.png">
      <AppInitializerContainer>
        <LocationProvider>
          <OnlineStatus>
            <AuthProvider>
              <ConfigProvider>
                <ProcessDataProvider>
                  <Routes />
                </ProcessDataProvider>
              </ConfigProvider>
            </AuthProvider>
          </OnlineStatus>
        </LocationProvider>
      </AppInitializerContainer>
    </CookieChecker>
  );
}

export default App;
