/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  getDeviceGeolocation,
  getPermissionState,
} from '@datapeace/1up-frontend-web-utils';
import { Button, Card, Flex, Loader } from '@datapeace/1up-frontend-web-ui';
import { useProcessDataContext, ROUTES } from '@datapeace/ams-web-components';
import { AMS_MODES } from '@datapeace/ams-web-hooks';

export function getFirstScreen(amsMode: ValueOf<typeof AMS_MODES>) {
  return amsMode === 'AUTO_CAPTURE' ? ROUTES.FACE_CAPTURE : ROUTES.WELCOME;
}

function GeolocationPermissionError() {
  return (
    <Flex center>
      <Card
        title="Cannot retrieve geolocation"
        type="danger"
        actions={[
          <Button onClick={() => window.location.reload()}>Refresh</Button>,
        ]}
      >
        Please ensure that permission for geolocation is provided
      </Card>
    </Flex>
  );
}

function CameraPermissionError() {
  return (
    <Flex center>
      <Card
        title="Cannot access camera"
        type="danger"
        actions={[
          <Button onClick={() => window.location.reload()}>Refresh</Button>,
        ]}
      >
        Please ensure that permission for camera is provided
      </Card>
    </Flex>
  );
}

export function Home(props: RouteComponentProps) {
  const { settings, handleResetProcess, navigateIfMounted } =
    useProcessDataContext();
  const [hasGeolocationPermission, setHasGeolocationPermission] =
    useState(true);
  const [hasCameraPermission, setHasCameraPermission] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if ((await getPermissionState('geolocation')) !== 'granted') {
          await getDeviceGeolocation();
        }
      } catch (err) {
        setHasGeolocationPermission(false);
        return;
      }

      try {
        // check camera permission
        if ((await getPermissionState('camera' as any)) !== 'granted') {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: false,
          });
          stream.getTracks().forEach((track) => track.stop());
        }
      } catch (err) {
        setHasCameraPermission(false);
        return;
      }
      navigateIfMounted(getFirstScreen(settings.amsMode));
      handleResetProcess();
    })();
  }, [settings.amsMode, navigateIfMounted, handleResetProcess]);

  if (!hasGeolocationPermission) return <GeolocationPermissionError />;
  if (!hasCameraPermission) return <CameraPermissionError />;

  return (
    <Flex center>
      <Loader />
    </Flex>
  );
}
