import React, { FC } from 'react';
import {
  IOrganizationDetailed,
  ISpace,
  IOrganizationConfig,
  IAmsConfig,
  getOrganizationSpaces,
} from '@datapeace/1up-frontend-shared-api';
import {
  Button,
  Card,
  Alert,
  SelectionPage,
  Flex,
  IErrorResponse,
  toast,
  CustomSelectionPage,
  CloseCircleOutlined,
  Loader,
  LogoutOutlined,
} from '@datapeace/1up-frontend-web-ui';
import { debounce } from '@datapeace/1up-frontend-web-utils';
import { Form, Input } from 'antd';
import { redirectToLogout } from '@datapeace/ams-web-utils';
import { useConfigContainer } from '@datapeace/ams-web-hooks';
import styles from './config-provider.module.scss';

// AMS app specific types
export interface IConfigContext extends IOrganizationConfig, IAmsConfig {
  email: string;
  isMobilePlan: boolean;
  organizations: IOrganizationDetailed[] | null;
  currentOrganization: IOrganizationDetailed | null;
  currentSpace: ISpace | null;
  clearCurrentSpace: () => void;
  clearCurrentOrganization: () => void;
}

const PAGE_SIZE = 10;
const defaultSearchFilterValue = {
  searchText: '',
};

export const ConfigProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    email,
    error,
    config,
    isLoading,
    organizations,
    currentOrganization,
    currentSpace,
    handleClearSpace,
    handleClearOrganization,
    Provider,
    handleSelectSpace,
    handleSelectOrganization,
  } = useConfigContainer();
  const [form] = Form.useForm();
  const [isFetchingOrganizationSpace, setIsFetchingOrganizationSpace] =
    React.useState(true);
  const [organizationSpaces, setOrganizationSpaces] = React.useState<ISpace[]>(
    []
  );
  const [apiParams, setApiParams] = React.useState({
    page: 1,
    searchFilterSubmittedValue: defaultSearchFilterValue.searchText,
  });

  const [organizationSpacesNext, setOrganizationSpacesNext] = React.useState<
    string | null
  >(null);

  const fetchOrganizationSpace = React.useCallback(
    async ({
      page = 1,
      searchText = defaultSearchFilterValue.searchText,
      clearPreviousData = false,
    }: {
      page: number;
      searchText: string;
      clearPreviousData: boolean;
    }) => {
      if (organizations && currentOrganization && !currentSpace) {
        setIsFetchingOrganizationSpace(true);
        clearPreviousData && setOrganizationSpaces([]);
        try {
          const { items, next } = await getOrganizationSpaces(
            currentOrganization?.id,
            {
              search: searchText,
              page,
              pageSize: PAGE_SIZE,
              active: 1,
            }
          );
          setOrganizationSpaces((prevState) => [...prevState, ...items]);
          setOrganizationSpacesNext(next);
          setApiParams({
            page,
            searchFilterSubmittedValue: searchText,
          });
        } catch (err) {
          toast.error(err as IErrorResponse);
        }

        setIsFetchingOrganizationSpace(false);
      }
    },
    [currentOrganization, currentSpace, organizations]
  );

  React.useEffect(() => {
    fetchOrganizationSpace({
      page: 1,
      searchText: '',
      clearPreviousData: true,
    });
  }, [fetchOrganizationSpace]);

  const handleSearchFilterSubmit = (value: string) => {
    fetchOrganizationSpace({
      page: 1,
      searchText: value,
      clearPreviousData: true,
    });
  };

  const handleLoadMoreOrganizationSpace = ({
    page,
    searchText,
  }: {
    page: number;
    searchText: string;
  }) => {
    if (!organizationSpacesNext) {
      return;
    }
    fetchOrganizationSpace({
      page,
      searchText,
      clearPreviousData: false,
    });
  };

  const handleSearchFilterSubmitWithDebounce = debounce(
    (searchText: string) => {
      handleSearchFilterSubmit(searchText);
    },
    400
  );

  if (isLoading) {
    return (
      <Flex center>
        <Loader />
      </Flex>
    );
  }

  if (!email) {
    throw new Error('Email not found in ConfigProvider');
  }

  if (error) {
    return (
      <Flex center>
        <Card
          title={error}
          avatar={<CloseCircleOutlined />}
          type="danger"
          actions={[
            <Button
              onClick={handleClearOrganization}
              size="large"
              type="primary"
            >
              Change Organization
            </Button>,
            <Button onClick={redirectToLogout} size="large" type="link" danger>
              Logout
            </Button>,
          ]}
        >
          Please ask for assistance from the concerned person in order to
          complete the process.
        </Card>
      </Flex>
    );
  }

  if (config) {
    const contextValue: IConfigContext = {
      ...config,
      email,
      organizations,
      currentOrganization,
      currentSpace,
      clearCurrentSpace: handleClearSpace,
      clearCurrentOrganization: handleClearOrganization,
    };
    return <Provider value={contextValue}>{children}</Provider>;
  }

  if (organizations && currentOrganization && !currentSpace) {
    return (
      <Flex center>
        <CustomSelectionPage
          key="space"
          title={
            <Form className={styles.Container} form={form} layout="vertical">
              <Form.Item
                name="choose-space"
                label="Choose Space"
                style={{
                  marginBottom: 0,
                  width: '100%',
                }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator() {
                      if (
                        getFieldValue('choose-space') !== '' &&
                        getFieldValue('choose-space').length < 3
                      ) {
                        return Promise.reject(
                          new Error(
                            'Please enter at least 3 characters to search.'
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Search
                  autoFocus
                  allowClear
                  placeholder="Type here to search space"
                  onChange={(event) => {
                    if (
                      event.target.value.length >= 3 ||
                      event.target.value === ''
                    ) {
                      handleSearchFilterSubmitWithDebounce(event.target.value);
                    }
                  }}
                  onSearch={(value, event) => {
                    event && event.preventDefault();
                    if (value !== '' && value.length >= 3) {
                      handleSearchFilterSubmitWithDebounce(value);
                    }
                  }}
                />
              </Form.Item>
            </Form>
          }
          onSelect={handleSelectSpace}
          isFetching={isFetchingOrganizationSpace}
          handleLoadMore={() => {
            handleLoadMoreOrganizationSpace({
              page: apiParams.page + 1,
              searchText: apiParams.searchFilterSubmittedValue,
            });
          }}
          hasMoreNextPageItems={organizationSpacesNext}
          options={organizationSpaces}
          onBack={
            organizations.length > 1 ? handleClearOrganization : undefined
          }
          emptyMessage="No spaces found!"
        />
      </Flex>
    );
  }

  if (organizations && !currentOrganization) {
    return (
      <Flex center>
        <SelectionPage
          key="org"
          title="Choose Organization"
          onSelect={handleSelectOrganization}
          options={organizations}
          extra={
            <Button onClick={redirectToLogout} danger icon={<LogoutOutlined />}>
              Logout
            </Button>
          }
          emptyMessage={
            <Alert
              style={{ width: '100%' }}
              message="No organizations with AMS plan found!"
              type="warning"
              showIcon
            />
          }
        />
      </Flex>
    );
  }

  return (
    <Flex center>
      <Loader />
    </Flex>
  );
};
