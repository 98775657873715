import { useState } from 'react';
import {
  Button,
  Alert,
  Modal,
  FaceCamera,
} from '@datapeace/1up-frontend-web-ui';
import {
  useTimeout,
  useIsWindowFocused,
} from '@datapeace/1up-frontend-web-utils';
import { useProcessDataContext } from '@datapeace/ams-web-components';

interface DistanceCallibratorProps {
  onClose: () => void;
}

function DistanceCallibrator({ onClose }: DistanceCallibratorProps) {
  const { settings, updateSettings } = useProcessDataContext();
  const [isFaceDetected, setIsFaceDetected] = useState(false);
  const [faceSizeValue, setFaceSizeValue] = useState(settings.minFaceSize);
  const { detectionScoreThreshold, cameraFacing, detectionDownscaling } =
    settings;

  useTimeout(() => setIsFaceDetected(false), isFaceDetected ? 200 : null);

  const handleCallibrationDone = () => {
    updateSettings({ minFaceSize: faceSizeValue });
    onClose();
  };

  const isWindowFocused = useIsWindowFocused(true);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Modal
      bodyStyle={{ padding: 0 }}
      visible
      onOk={handleCallibrationDone}
      okText="Calibrate to this distance"
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={onClose}
      centered
      okButtonProps={{ disabled: !isFaceDetected, size: 'large' }}
    >
      {isWindowFocused ? (
        <FaceCamera
          videoConstraints={{ facingMode: cameraFacing }}
          onDetection={(status, detection) => {
            setFaceSizeValue(
              Math.round(detection.scale * detectionDownscaling)
            );
            setIsFaceDetected(status === 'YES');
          }}
          hideCaptureButton
          captureAreaOffset={0}
          detectionScoreThreshold={detectionScoreThreshold}
          validRangeMinFaceSize={detectionScoreThreshold}
          captureAreaBoxSize={0}
          autoCapture={false}
          downscaleBy={detectionDownscaling}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            height: '100%',
          }}
        >
          <Alert
            message="Tap on screen to see camera view!"
            description="This window is out of focus. Tap here to see the camera view."
            type="warning"
            showIcon
          />
        </div>
      )}
    </Modal>
  );
}

export function DistanceCalibrationButton() {
  const [isCallibrationWindowOpen, setCallibrationWindowOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setCallibrationWindowOpen(true)}>Calibrate</Button>
      {isCallibrationWindowOpen && (
        <DistanceCallibrator onClose={() => setCallibrationWindowOpen(false)} />
      )}
    </>
  );
}
