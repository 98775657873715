import { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  Button,
  CheckCircleOutlined,
  LogoutOutlined,
  SettingOutlined,
  Flex,
  FlexItem,
} from '@datapeace/1up-frontend-web-ui';
import { useProcessDataContext } from '@datapeace/ams-web-components';
import { BRAND_NAME } from '@datapeace/ams-web-utils';
import styles from './welcome.module.scss';

export function Welcome(props: RouteComponentProps) {
  const {
    config,
    settings,
    handleResetProcess,
    handleNavigateToSettings,
    handleStartProcess,
  } = useProcessDataContext();

  if (!config) throw new Error('config not found in Welcome');

  const { portalLogoUrl, portalBackgroundImageUrl, punchButtonLabel } = config;
  const { processType } = settings;

  useEffect(() => {
    handleResetProcess();
  }, [handleResetProcess]);

  return (
    <Flex
      vertical
      className={styles.Welcome}
      style={{ backgroundImage: `url(${portalBackgroundImageUrl})` }}
    >
      <Flex center vertical className={styles.Content}>
        {portalLogoUrl && (
          <img src={portalLogoUrl} className={styles.Logo} alt="1upAMS" />
        )}

        {/* {portalTitle && <Typography.Title className={styles.Title}>{portalTitle}</Typography.Title>} */}

        <div className={styles.ButtonsContainer}>
          <Button size="xLarge" onClick={handleStartProcess} type="primary">
            {punchButtonLabel || (
              <>
                {processType === 'IN' && 'Punch In'}
                {processType === 'OUT' && 'Punch Out'}
                {processType === 'BOTH' && 'Punch In/Out'}
              </>
            )}
            &nbsp;
            {processType === 'OUT' ? (
              <LogoutOutlined />
            ) : (
              <CheckCircleOutlined />
            )}
          </Button>
        </div>
      </Flex>

      <FlexItem>
        <Button
          className={styles.SettingsButton}
          type="link"
          icon={<SettingOutlined />}
          shape="circle"
          onClick={handleNavigateToSettings}
        />
        <span>
          Powered by&nbsp;
          {BRAND_NAME}
        </span>
      </FlexItem>
    </Flex>
  );
}
