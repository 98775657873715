import React, { useContext } from 'react';
import { Form as AntdForm } from 'antd';
import { FormItemProps as AntdFormItemProps } from 'antd/lib/form';
import clsx from 'clsx';
import styles from './form.module.scss';
import { FormContext } from '.';

export interface FormItemProps
  extends Pick<
    AntdFormItemProps,
    | 'noStyle'
    | 'hasFeedback'
    | 'validateStatus'
    | 'required'
    | 'hidden'
    | 'help'
    | 'extra'
    | 'colon'
    | 'label'
    | 'name'
    | 'requiredMark'
  > {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const FormItem: React.FC<FormItemProps> = ({
  // enter props here

  className,
  children,
  ...rest
}) => {
  const { size, horizontal } = useContext(FormContext);

  return (
    <AntdForm.Item
      className={clsx(
        styles.FormItem,
        size === 'xLarge' && styles.FormItemXLarge,
        horizontal && styles.FormItemHorizontal,
        className
      )}
      {...rest}
    >
      {children}
    </AntdForm.Item>
  );
};
