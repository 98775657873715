import { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  Button,
  Alert,
  Spin,
  ArrowLeftOutlined,
  FaceCamera,
  LoadingOutlined,
  SettingOutlined,
  UserOutlined,
} from '@datapeace/1up-frontend-web-ui';
import clsx from 'clsx';
import {
  IBlobWithDataURL,
  useCountdown,
  useIsWindowFocused,
  useIsMounted,
} from '@datapeace/1up-frontend-web-utils';
import { useProcessDataContext } from '@datapeace/ams-web-components';
import { getFirstScreen } from '../index';
import styles from './face-capture.module.scss';

export function FaceCapture(props: RouteComponentProps) {
  const {
    settings,
    handleFaceCaptureSubmit,
    handleNavigateToHome,
    handleNavigateToSettings,
  } = useProcessDataContext();
  const {
    detectionScoreThreshold,
    minFaceSize,
    amsMode,
    isAutoSubmitEnabled,
    cameraFacing,
    detectionDownscaling,
  } = settings;

  const isMountedRef = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);

  const isFirstScreen = getFirstScreen(amsMode) === '/face-capture';

  const handleCapture = async (faceData: IBlobWithDataURL | null) => {
    if (isLoading) return;
    try {
      if (!faceData) throw new Error('Failed to capture!');
      setIsLoading(true);
      await handleFaceCaptureSubmit(faceData);
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      if (isMountedRef.current) setIsLoading(false);
    }
  };

  const isWindowFocused = useIsWindowFocused(true);

  const [transitionDelay, resetTransitionDelay] = useCountdown(1, 200);
  useEffect(() => {
    if (!isLoading) resetTransitionDelay();
  }, [isLoading, resetTransitionDelay]);

  return (
    <div className={styles.Layout}>
      {!isLoading && (
        <div
          className={clsx(
            styles.HintText,
            !!transitionDelay && styles.HintTextInitial
          )}
        >
          <div>
            <span>
              <UserOutlined className={styles.HintTextIcon} />
              Take photo
            </span>
          </div>
        </div>
      )}
      {isLoading && (
        <div className={styles.HintText}>
          <div>
            <Spin indicator={<LoadingOutlined />} size="large" />
          </div>
        </div>
      )}
      <div className={styles.Content}>
        {isWindowFocused ? (
          <FaceCamera
            videoConstraints={{ facingMode: cameraFacing || 'user' }}
            autoCapture={isAutoSubmitEnabled}
            validRangeMinFaceSize={minFaceSize}
            detectionScoreThreshold={detectionScoreThreshold}
            captureAreaBoxSize={0}
            loading={isLoading}
            onCapture={handleCapture}
            downscaleBy={detectionDownscaling}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1rem',
            }}
          >
            <Alert
              message="Tap on screen to see camera view!"
              description="This window is out of focus. Tap here to see the camera view."
              type="warning"
              showIcon
            />
          </div>
        )}
      </div>

      {!isFirstScreen && (
        <Button
          onClick={handleNavigateToHome}
          className={styles.BackButton}
          size="large"
          icon={<ArrowLeftOutlined />}
          shape="circle"
        />
      )}

      {isFirstScreen && (
        <Button
          className={styles.BackButton}
          icon={<SettingOutlined />}
          shape="circle"
          onClick={handleNavigateToSettings}
        />
      )}
    </div>
  );
}
