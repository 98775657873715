/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  Button,
  Alert,
  StatusCard,
  Flex,
  MobileOutlined,
  HomeOutlined,
  UndoOutlined,
} from '@datapeace/1up-frontend-web-ui';
import { useCountdown } from '@datapeace/1up-frontend-web-utils';
import { getMaskedMobileNumber } from '@datapeace/1up-frontend-shared-api';
import { useProcessDataContext } from '@datapeace/ams-web-components';
import { useConfig } from '@datapeace/ams-web-hooks';
import styles from './confirm.module.scss';

function useRedirectAfterSuccess(isSuccess: boolean) {
  const { settings, handleNavigateToHome } = useProcessDataContext();

  // after set seconds of successful checkin/checkout redirect to home
  const [resendDelay] = useCountdown(
    settings.alertScreenDelay,
    isSuccess ? 1000 : null
  );

  useEffect(() => {
    if (isSuccess && resendDelay === 0) {
      handleNavigateToHome();
    }
  }, [isSuccess, resendDelay, handleNavigateToHome]);

  return resendDelay;
}

export function Confirm(props: RouteComponentProps) {
  const {
    faceData,
    personData,
    handleConfirmSubmit,
    handleNavigateToHome,
    settings,
  } = useProcessDataContext();
  const { portalLogoUrl } = useConfig();
  const [punchType, setPunchType] = useState<'IN' | 'OUT'>('IN');
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'error' | 'success'
  >('idle');
  const [error, setError] = useState('');

  const resendDelay = useRedirectAfterSuccess(
    status === 'success' || status === 'error'
  );

  const handleSubmit = useCallback(async () => {
    try {
      setStatus('loading');
      const res = await handleConfirmSubmit();
      setPunchType(res.punchType);
      setStatus('success');
    } catch (err) {
      setStatus('error');
      setError(
        `${
          (err as any).info
            ? `${(err as any).info.title}${
                (err as any).info.display_text &&
                `: ${(err as any).info.display_text}`
              }`
            : (err as any).message
        }`
      );
    }
  }, [handleConfirmSubmit]);

  useEffect(() => {
    if (settings.isAutoSubmitEnabled) handleSubmit();
  }, [settings.isAutoSubmitEnabled, handleSubmit]);

  return (
    <Flex center>
      {portalLogoUrl && (
        <img src={portalLogoUrl} className={styles.BrandImage} alt="1upAMS" />
      )}
      <Button
        onClick={handleNavigateToHome}
        size="large"
        shape="circle"
        className={styles.HomeButton}
        icon={<HomeOutlined />}
      />

      <div style={{ maxWidth: '100%' }}>
        <StatusCard
          highlight
          status={status}
          avatar={faceData?.dataUrl}
          title={`${personData?.firstName || ''} ${personData?.lastName || ''}`}
          meta={
            <>
              <MobileOutlined />
              &nbsp;
              {personData?.mobileNumber &&
                getMaskedMobileNumber(personData.mobileNumber)}
            </>
          }
          idleMessage="Click on the button below to record the punch"
          idleActions={[
            <Button size="large" onClick={handleSubmit} type="primary">
              Punch now
            </Button>,
          ]}
          loadingMessage="Processing... please wait."
          loadingActions={[
            <Button disabled size="large" onClick={handleSubmit} type="primary">
              Processing...
            </Button>,
          ]}
          errorMessage={error || 'Failed! Please retry.'}
          errorActions={[
            <Button
              size="large"
              icon={<UndoOutlined />}
              onClick={handleSubmit}
              danger
            >
              Retry
            </Button>,
          ]}
          successMessage={`Punched ${punchType.toLowerCase()} successfully!`}
        />

        {(status === 'success' || status === 'error') && (
          <Alert
            className={styles.RedirectAlert}
            type={status}
            message={
              <>
                {`This screen will close in ${resendDelay} seconds `}
                <Button onClick={handleNavigateToHome} type="dashed">
                  Close now
                </Button>
              </>
            }
            banner
          />
        )}
      </div>
    </Flex>
  );
}
