import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  configure,
  storage,
  getDeviceFingerprint,
  removeDeprecatedDeviceFingerprintId,
  LOGGED_OUT_EVENT,
} from '@datapeace/1up-frontend-shared-api';
import { Loader } from '@datapeace/1up-frontend-web-ui';

Sentry.configureScope(async (scope) => {
  scope.setUser({
    username: await getDeviceFingerprint(),
    deviceId: await getDeviceFingerprint(),
  });
});

configure({
  siteId: 'web-ams',
  siteVersion: __VERSION__,
  onLogout: () => {
    window.dispatchEvent(new Event(LOGGED_OUT_EVENT));
    return null;
  },
  deviceIdGenerator: getDeviceFingerprint,
});

export function AppInitializerContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    async function initialize() {
      const authToken = await storage.getItem('auth_token');
      if (authToken) {
        await storage.setItem('access_token', authToken);
        await storage.removeItem('auth_token');
      }
      setIsInitialized(true);
    }
    initialize();

    // remove the deprecated device fingerprint key
    removeDeprecatedDeviceFingerprintId();
  }, []);

  if (!isInitialized) return <Loader text="Loading" />;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
