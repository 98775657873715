import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  Button,
  StatusCardProps,
  StatusCard,
  Alert,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  Flex,
  HomeOutlined,
  MedicineBoxOutlined,
  ReloadOutlined,
  IErrorResponse,
} from '@datapeace/1up-frontend-web-ui';
import { useIsMounted, useCountdown } from '@datapeace/1up-frontend-web-utils';
import { useProcessDataContext } from '@datapeace/ams-web-components';
import { useConfig } from '@datapeace/ams-web-hooks';
import styles from '../confirm/confirm.module.scss';

function useRedirectOnError(shouldRedirect: boolean) {
  const { settings, handleTemperatureSubmit } = useProcessDataContext();

  // after set seconds of successful checkin/checkout redirect to home
  const [redirectDelay] = useCountdown(
    settings.alertScreenDelay,
    shouldRedirect ? 1000 : null
  );

  useEffect(() => {
    if (shouldRedirect && redirectDelay === 0) {
      handleTemperatureSubmit();
    }
  }, [shouldRedirect, redirectDelay, handleTemperatureSubmit]);

  return redirectDelay;
}

export function Temperature(props: RouteComponentProps) {
  const {
    settings,
    handleNavigateToHome,
    handleRecordTemperature,
    handleTemperatureSubmit,
  } = useProcessDataContext();

  const isMountedRef = useIsMounted();
  const { portalLogoUrl } = useConfig();
  const [status, setStatus] = useState<StatusCardProps['status']>('idle');
  const [error, setError] = useState('');

  const shouldAutoSkip = settings.isAutoSubmitEnabled && status === 'error';
  const redirectDelay = useRedirectOnError(shouldAutoSkip);

  const fetchTemperature = useCallback(async () => {
    setStatus('loading');
    setError('');

    try {
      await handleRecordTemperature();
      if (!isMountedRef.current) return;
      setStatus('success');
    } catch (err) {
      if (!isMountedRef.current) return;
      setError((err as IErrorResponse).message);
      setStatus('error');
    }
  }, [handleRecordTemperature, isMountedRef]);

  useEffect(() => {
    fetchTemperature();
  }, [fetchTemperature]);

  const backButton = (
    <Button
      icon={<ArrowLeftOutlined />}
      onClick={handleNavigateToHome}
      size="large"
      type="link"
    >
      Back
    </Button>
  );

  return (
    <Flex center>
      {portalLogoUrl && (
        <img src={portalLogoUrl} className={styles.BrandImage} alt="1upAMS" />
      )}
      <Button
        onClick={handleNavigateToHome}
        size="large"
        shape="circle"
        className={styles.HomeButton}
        icon={<HomeOutlined />}
      />

      <div>
        <StatusCard
          avatar={<MedicineBoxOutlined />}
          status={status}
          meta="Please take your temperature"
          loadingMessage="Waiting for temperature..."
          successMessage="Temperature recorded successfully"
          errorMessage={error}
          idleActions={[backButton]}
          errorActions={[
            backButton,
            <Button
              size="large"
              icon={<ReloadOutlined />}
              onClick={fetchTemperature}
              type="primary"
            >
              Retry
            </Button>,
            <Button onClick={handleTemperatureSubmit} size="large" type="link">
              Skip
              <ArrowRightOutlined />
            </Button>,
          ]}
          successActions={[
            backButton,
            <Button
              onClick={handleTemperatureSubmit}
              size="large"
              type="primary"
            >
              Continue
              <ArrowRightOutlined />
            </Button>,
          ]}
        />

        {shouldAutoSkip && (
          <Alert
            className={styles.RedirectAlert}
            type="warning"
            message={`This screen will close in ${redirectDelay} seconds `}
            banner
          />
        )}
      </div>
    </Flex>
  );
}
