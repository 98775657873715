import { RouteComponentProps } from '@reach/router';
import {
  Button,
  Card,
  Typography,
  Divider,
  InputNumber,
  Popconfirm,
  ArrowLeftOutlined,
  SyncOutlined,
  ReloadOutlined,
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
  Flex,
  FlexItem,
  Form,
  FormItem,
  LogoutOutlined,
} from '@datapeace/1up-frontend-web-ui';
import { Dropdown, Menu, message, Select, Radio as AntdRadio } from 'antd';
import { FREQUENCY } from '@datapeace/1up-frontend-shared-api';
import {
  DistanceCalibrationButton,
  useProcessDataContext,
} from '@datapeace/ams-web-components';
import {
  PROCESS_TYPES,
  StoredSettings,
  AMS_MODES,
  detectionScoreThresholdRange,
  defaultSettings,
} from '@datapeace/ams-web-hooks';
import { onLogout, redirectToLogout } from '@datapeace/ams-web-utils';
import styles from './settings.module.scss';

const RadioGroup = AntdRadio.Group;
const RadioButton = AntdRadio.Button;
const SelectOption = Select.Option;

const UPGRADE_MESSAGE = 'Please upgrade your plan to access this feature!';

export function Settings(props: RouteComponentProps) {
  const { handleNavigateToHome, settings, updateSettings, config } =
    useProcessDataContext();
  const {
    clearCurrentOrganization,
    organizations,
    clearCurrentSpace,
    currentOrganization,
    currentSpace,
    email,
    temperatureDevices,
    temperatureFrequency,
  } = config;

  const logoUrl = currentOrganization?.logoUrl || '';
  const spaceName = currentSpace?.name || '';
  const features = currentOrganization?.features;
  const menu = (
    <Menu
      onClick={async () => {
        const hide = message.loading('Logging Out', 0);
        await onLogout();
        hide();
        redirectToLogout();
      }}
    >
      <Menu.Item key="1">
        <LogoutOutlined style={{ fontSize: '13px', marginRight: '5px' }} />
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Flex vertical>
      <FlexItem>
        <Flex wrap centerItems className={styles.Header}>
          {logoUrl && (
            <img className={styles.Logo} src={logoUrl} alt={spaceName} />
          )}
          <Typography.Title level={4}>Settings</Typography.Title>

          <FlexItem grow />

          <Typography.Text>{email}</Typography.Text>
          <Dropdown.Button
            onClick={() => window.location.reload()}
            overlay={menu}
            trigger={['click', 'hover']}
            icon={<CaretDownOutlined />}
          >
            <ReloadOutlined />
            Refresh
          </Dropdown.Button>
          <Button
            type="primary"
            icon={<CloseOutlined />}
            onClick={handleNavigateToHome}
          >
            Close
          </Button>
        </Flex>
      </FlexItem>

      <FlexItem grow style={{ overflow: 'auto' }}>
        <Flex centerContent style={{ height: 'auto' }}>
          <Card style={{ width: 600, margin: '1em' }}>
            <Form
              initialValues={settings}
              fields={Object.entries(settings).map(([key, value]) => ({
                name: key,
                value,
              }))}
              onValuesChange={(_, allValues: StoredSettings) =>
                updateSettings(allValues)
              }
              horizontal
            >
              {!!organizations && !!currentOrganization && (
                <>
                  <FormItem label="Organization">
                    <Typography.Text>
                      {currentOrganization.name}
                      &nbsp;
                      {organizations.length > 1 && (
                        <Button
                          type="link"
                          onClick={() => clearCurrentOrganization()}
                        >
                          Change
                        </Button>
                      )}
                    </Typography.Text>
                  </FormItem>

                  {!!currentSpace && (
                    <FormItem label="Space">
                      <Typography.Text>
                        {currentSpace.name}
                        &nbsp;
                        {currentOrganization.spaces.length > 1 && (
                          <Button
                            type="link"
                            onClick={() => clearCurrentSpace()}
                          >
                            Change
                          </Button>
                        )}
                      </Typography.Text>
                    </FormItem>
                  )}
                </>
              )}

              <Divider />

              <FormItem name="amsMode" label="AMS mode">
                <Select style={{ textAlign: 'left', width: 220 }}>
                  <SelectOption value={AMS_MODES.NORMAL}>
                    <CheckOutlined />
                    {' Normal Mode'}
                  </SelectOption>

                  <SelectOption
                    disabled={!features?.spacesAmsAppAutoCaptureMode}
                    title={
                      !features?.spacesAmsAppAutoCaptureMode
                        ? UPGRADE_MESSAGE
                        : ''
                    }
                    value={AMS_MODES.AUTO_CAPTURE}
                  >
                    <SyncOutlined />
                    {' Auto capture (Face)'}
                  </SelectOption>
                </Select>
              </FormItem>

              <FormItem name="processType" label="Punch Type">
                <RadioGroup>
                  <RadioButton value={PROCESS_TYPES.IN}>In</RadioButton>
                  <RadioButton value={PROCESS_TYPES.OUT}>Out</RadioButton>
                  <RadioButton value={PROCESS_TYPES.BOTH}>Both</RadioButton>
                </RadioGroup>
              </FormItem>

              <FormItem
                name="alertScreenDelay"
                label="Success/Error screen delay"
              >
                <InputNumber
                  style={{ width: 80 }}
                  min={0.5}
                  max={5}
                  step={0.5}
                  placeholder={defaultSettings.alertScreenDelay.toString()}
                />
              </FormItem>

              <Divider />

              <FormItem name="cameraFacing" label="Camera facing">
                <RadioGroup>
                  <RadioButton value="user">Front camera</RadioButton>
                  <RadioButton value="environment">Rear camera</RadioButton>
                </RadioGroup>
              </FormItem>

              <FormItem
                name="detectionScoreThreshold"
                label="Detection Score Threshold"
              >
                <InputNumber
                  style={{ width: 80 }}
                  min={detectionScoreThresholdRange.min}
                  max={detectionScoreThresholdRange.max}
                  step={5}
                  placeholder={defaultSettings.detectionScoreThreshold.toString()}
                />
              </FormItem>

              {/* <FormItem name="detectionPreference" label="Optimize detection for">
                <RadioGroup>
                  <RadioButton value="performance">Performance</RadioButton>
                  <RadioButton value="quality">Quality</RadioButton>
                </RadioGroup>
              </FormItem> */}

              <FormItem label="Calibrate detection distance">
                <div style={{ display: 'inline-block' }}>
                  <FormItem name="minFaceSize" noStyle>
                    <InputNumber
                      min={50}
                      max={500}
                      style={{ width: 80, marginRight: 10, opacity: 0.7 }}
                      placeholder={defaultSettings.minFaceSize.toString()}
                    />
                  </FormItem>
                  <DistanceCalibrationButton />
                </div>
              </FormItem>

              {temperatureFrequency !== FREQUENCY.NEVER &&
                features?.spacesVisitorBodyTemperature && (
                  <>
                    <Divider />
                    <FormItem label="Waiting time for temperature (in sec)">
                      <FormItem name="temperatureWaitTime" noStyle>
                        <InputNumber
                          min={5}
                          style={{ width: 80, marginRight: 10, opacity: 0.8 }}
                          placeholder={defaultSettings.temperatureWaitTime.toString()}
                        />
                      </FormItem>
                    </FormItem>
                    <FormItem label="Temperature Device">
                      <FormItem name="temperatureDeviceId" noStyle>
                        <Select
                          disabled={!temperatureDevices?.length}
                          placeholder={
                            temperatureDevices?.length
                              ? 'Select Device'
                              : 'No device found'
                          }
                          style={{ textAlign: 'left', width: 220 }}
                        >
                          {temperatureDevices?.map((device) => (
                            <SelectOption
                              key={`temperatureDevice_${device.id}`}
                              value={device.id}
                            >
                              {device.name}
                            </SelectOption>
                          ))}
                        </Select>
                      </FormItem>
                    </FormItem>
                  </>
                )}

              <Divider />

              <FormItem
                name="isSoundEnabled"
                label="Sound"
                extra={!features?.spacesAmsAppPunchSound ? UPGRADE_MESSAGE : ''}
                style={{ textAlign: 'right' }}
              >
                <RadioGroup disabled={!features?.spacesAmsAppPunchSound}>
                  <RadioButton value>On</RadioButton>
                  <RadioButton value={false}>Off</RadioButton>
                </RadioGroup>
              </FormItem>
            </Form>

            <Popconfirm
              title="Reset all settings to default?"
              onConfirm={() => updateSettings(defaultSettings)}
            >
              <Button className={styles.ResetButton}>Reset to default</Button>
            </Popconfirm>
          </Card>
        </Flex>
      </FlexItem>

      <FlexItem>
        <Flex
          centerItems
          placeContent="space-between"
          className={styles.Footer}
        >
          <Button
            onClick={handleNavigateToHome}
            shape="circle"
            icon={<ArrowLeftOutlined />}
          />
          <Typography.Text title="Go back">{__VERSION__}</Typography.Text>
          <FlexItem />
        </Flex>
      </FlexItem>
    </Flex>
  );
}
