import React from 'react';
import { Form as AntdForm } from 'antd';
import { FormProps as AntdFormProps } from 'antd/lib/form';
import FormList from 'antd/lib/form/FormList';
import clsx from 'clsx';
import { FormContext, FormSizeType } from './form-context';
import styles from './form.module.scss';

const { useForm, Provider: FormProvider } = AntdForm;

export interface FormProps
  extends Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'>,
    Pick<
      AntdFormProps,
      | 'hideRequiredMark'
      | 'colon'
      | 'name'
      | 'form'
      | 'scrollToFirstError'
      | 'initialValues'
      | 'fields'
      | 'onValuesChange'
      | 'onFieldsChange'
      | 'onFinish'
      | 'onFinishFailed'
      | 'requiredMark'
    > {
  size?: FormSizeType;
  horizontal?: boolean;

  className?: string;
  style?: React.CSSProperties;
}

export const Form: React.FC<FormProps> = ({
  size,
  horizontal,
  className,
  children,
  ...rest
}) => {
  return (
    <FormContext.Provider value={{ size, horizontal }}>
      <AntdForm
        layout={horizontal ? 'horizontal' : 'vertical'}
        size={size !== 'xLarge' ? size : undefined}
        className={clsx(
          styles.Form,
          size === 'xLarge' && styles.FormXLarge,
          horizontal && styles.FormHorizontal,
          className
        )}
        {...rest}
      >
        {children}
      </AntdForm>
    </FormContext.Provider>
  );
};

export { useForm, FormProvider, FormList };
